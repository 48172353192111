.button {
  padding: 14px 24px;
  min-width: 160px;
  border-radius: 100px;
  text-align: center;
  font-size: 1.2rem;
  font-weight: 600;
  cursor: pointer;
  border: 1px solid;

  &.button--dark {
    color: white;
    border-color: #00203fff;
    background: #00203fff;

    &:hover {
      border-color: #0d1630;
      background: #0d1630;
    }

    &:active {
      border-color: #060b18;
      background: #060b18;
    }
  }

  &.button--light {
    color: #08132d;
    border-color: white;
    background: white;

    &:hover {
      border-color: #eff3fb;
      background: #eff3fb;
    }

    &:active {
      border-color: #e7edf9;
      background: #e7edf9;
    }
  }

  &.button--transparent {
    color: white;
    border-color: #4070f4;
    background: #ffffff00;

    &:hover {
      background: #08132d12;
    }

    &:active {
      background: #08132d1e;
    }
  }
}