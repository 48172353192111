.breadcrumbs {
  background: white;
  font-size: 1.4rem;
  padding: 16px 0px;
  border-bottom: 1px solid #dfe8fc;

  b {
    &.separator {
      color: #777;
      display: inline-block;
      transform: translateY(-1px);
      padding: 0px 4px;
    }
  }
}
.hero-section {
  background-color: #ADEFD1FF;
  color: #ffffff;
  .hero__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    padding: 64px 0px;
    align-items: flex-start;
    @media all and (max-width: 1200px) {
      padding: 32px 0px 38px;
    }
    h1 {
      font-weight: 700;
      font-size: 4rem;
      line-height: 120%;
      padding-bottom: 24px;
      color: #00203FFF;
      @media all and (max-width: 1200px) {
        font-size: 3.2rem;
      }
    }

    p {
      color: #00203FFF;
      font-weight: 500;
      font-size: 1.4rem;
      line-height: 160%;
      padding-bottom: 32px;
    }
  }

  &.compact-version {
    .hero__content {
      align-items: center;
      padding: 64px 0px 38px;

      @media all and (max-width: 1200px) {
        padding: 48px 0px 16px;
      }
      @media all and (max-width: 900px) {
        padding: 48px 0px 0px;
      }
      p {
        font-size: 1.6rem;
        @media all and (max-width: 1200px) {
          // padding-bottom: 16px;
        }
      }
    }
  }

  .hero__image {
    width: 100%;
    height: 100%;
    min-height: 512px;
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
    @media all and (max-width: 1200px) {
      min-height: 356px;
    }
    @media all and (max-width: 600px) {
      min-height: 256px;
    }
  }
  @media all and (max-width: 900px) {
    padding-bottom: 32px;
  }
  @media all and (max-width: 600px) {
    padding-bottom: 16px;
  }
}
